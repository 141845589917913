import { Button, Form, InputNumber, Spin } from 'antd';
import React, { useState, useEffect } from 'react';
import { useIntl, getLocale } from 'umi';
import { getAdolescentRecommendProject } from 'cognitiveleap-core-us/report/utils';
import { GetLastCustomPlan } from 'services/rocketService/Assessment';

import styles from '../detail.less';

const LangOptions = {
  'en-US': 'en',
  'zh-CN': 'zh-Hans',
  'es-ES': 'es-ES',
};

const formItemLayout = {
  labelCol: {
    span: 10,
  },
  wrapperCol: {
    span: 14,
  },
};

const regex = /\([^)]*?(?:课时|sessions)[^)]*?\)/g; // 匹配括号内包含课时或sessions的内容

const StartPlanModal = ({ activePlan, onStartPlan, btnLoading }) => {
  const intl = useIntl();

  const { id, assessmentStatus = [] } = activePlan || {};

  const language = LangOptions[getLocale()];

  const [assessmentData, setAssessmentData] = useState({});

  const initData = async () => {
    const res = await GetLastCustomPlan({
      userOfflineTrainingPlanId: id,
      fromDatabase: Boolean(assessmentStatus.length),
    });

    if (res.response.ok) {
      setAssessmentData(res.data);
    }
  };

  useEffect(() => {
    initData();
  }, []);

  const { arrangeableClass, recommendDifficulty, recommendMusicCourse } =
    assessmentData || {};

  const recommendProject =
    getAdolescentRecommendProject(
      `(${recommendMusicCourse?.toUpperCase()})`,
      language,
    ) || recommendMusicCourse?.toUpperCase();

  if (!Object.keys(assessmentData).length) return <></>;

  return (
    <div className={styles.startPlanModal}>
      <Form
        name="startPlan"
        {...formItemLayout}
        onFinish={onStartPlan}
        initialValues={{
          // totalNum: arrangeableClass,
          startDifficulty: recommendDifficulty,
        }}
      >
        <Form.Item label={intl.formatMessage({ id: 'Recommend Music Module' })}>
          <span>{recommendProject?.replace(regex, '').trim()}</span>
        </Form.Item>
        <Form.Item label={intl.formatMessage({ id: 'Total number of hours' })}>
          <span>{arrangeableClass}</span>
        </Form.Item>
        <Form.Item
          name="totalNum"
          label={intl.formatMessage({ id: 'CurrSessions' })}
          rules={[{ required: true }]}
        >
          <InputNumber min={1} max={arrangeableClass} />
        </Form.Item>
        <Form.Item
          name="startDifficulty"
          label={intl.formatMessage({ id: 'Initial difficulty' })}
          rules={[{ required: true  }]}
        >
          <InputNumber min={1} max={10} />
        </Form.Item>
        <Form.Item
          wrapperCol={{
            span: 12,
            offset: 6,
          }}
        >
          <Button type="primary" htmlType="submit" loading={btnLoading}>
            {intl.formatMessage({ id: 'startPlan2' })}
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default StartPlanModal;
