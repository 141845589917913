import { IntlManager } from 'utils/helper'

export const tableHeadTitle = () => {
    const formatMessage = IntlManager.intl.formatMessage
    return [
        { name: formatMessage({ id: 'studentName' }), style: { width: '22%' } },
        { name: `${formatMessage({ id: 'session' })}：`, style: { width: '7%' } },
        { name: `${formatMessage({ id: 'music' })}：`, style: { width: '25%' } },
        { name: `${formatMessage({ id: 'volumeOnly' })}：`, style: { width: '8%' } },
        { name: `${formatMessage({ id: 'playingDurationMins' })}：`, style: { width: '13%' } },
        { name: `${formatMessage({ id: 'estimatedTrainingDuration' })}：`, style: { width: '11%', display: 'none' } },
        { name: `${formatMessage({ id: 'trainingDate' })}：`, style: { width: '13%' } },
        { name: `${formatMessage({ id: 'trainer' })}：`, style: { width: '12%' } }
    ]
}

export const tableContentHeadTitle = () => {
    const formatMessage = IntlManager.intl.formatMessage
    return [
        { name: formatMessage({ id: 'numberOrderOther' }), style: { width: '40px' }, propertyName: 'index' },
        { name: formatMessage({ id: 'trainingActivities' }), style: { width: 'calc(20% - 40px)' }, propertyName: 'planname' },
        { name: formatMessage({ id: 'trainingGoals' }), style: { width: '26%' }, propertyName: 'plantarget' },
        { name: formatMessage({ id: 'groupOne' }), style: { width: '8%', 'word-wrap': 'break-word', 'word-break': 'break-all' }, propertyName: 'firstRound' },
        { name: formatMessage({ id: 'groupTwo' }), style: { width: '8%', 'word-wrap': 'break-word', 'word-break': 'break-all' }, propertyName: 'secondRound' },
        { name: formatMessage({ id: 'groupThere' }), style: { width: '8%', 'word-wrap': 'break-word', 'word-break': 'break-all' }, propertyName: 'thirdRound' },
        { name: formatMessage({ id: 'cofidence' }), style: { width: '10%' }, propertyName: 'cofidence' },
        { name: formatMessage({ id: 'physicalStrength2' }), style: { width: '10%' }, propertyName: 'physicalStrength2' },
        { name: formatMessage({ id: 'standardity' }), style: { width: '10%' }, propertyName: 'standardity' },
        { name: formatMessage({ id: 'comments' }), style: { width: '15%', 'word-wrap': 'break-word', 'word-break': 'break-all' }, propertyName: 'notes' }
    ]
}

export const groupTableContentHeadTitle = () => {
    const formatMessage = IntlManager.intl.formatMessage
    return [
        { name: formatMessage({ id: 'numberOrderOther' }), style: { width: '40px' }, propertyName: 'index' },
        { name: formatMessage({ id: 'trainingActivities' }), style: { width: 'calc(20% - 40px)' }, propertyName: 'planname' },
        { name: formatMessage({ id: 'trainingGoals' }), style: { width: '30%' }, propertyName: 'plantarget' },
        { name: formatMessage({ id: 'levelOfDifficulty' }), style: { width: '12%' }, propertyName: 'difficultySelection' },
        { name: formatMessage({ id: 'levelOfLikeability' }), style: { width: '12%' }, propertyName: 'likingRating' },
        { name: formatMessage({ id: 'comments', 'word-wrap': 'break-word', 'word-break': 'break-all' }), style: { width: '31%' }, propertyName: 'notes' }
    ]
}

export const table2Content1Title = () => {
    const formatMessage = IntlManager.intl.formatMessage

    return [
        { name: '8', style: { width: '40px' }, propertyName: 'text' },
        { name: formatMessage({ id: 'observedPerformance' }), style: { width: '15%', fontWeight: 550, fontSize: '15px' }, propertyName: 'text' },
        { name: formatMessage({ id: 'followInstructions' }), style: { width: '15%' }, propertyName: 'text' },
        { name: '', style: { width: '12%' }, propertyName: 'image', imageType: 'circular', imageCount: 0, isFeed: false },
        { name: formatMessage({ id: 'attention' }), style: { width: 'calc(17% - 20px)' }, propertyName: 'text' },
        { name: '', style: { width: '12%' }, propertyName: 'image', imageType: 'rectangle', imageCount: 0, isFeed: false },
        { name: formatMessage({ id: 'hyperactivity_impulsivity' }), style: { width: 'calc(17% - 20px)' }, propertyName: 'text' },
        { name: '', style: { width: '12%' }, propertyName: 'image', imageType: 'diamond', imageCount: 0, isFeed: false },
    ]
}

export const oneTomoreTable2Content2Title = () => {
    const formatMessage = IntlManager.intl.formatMessage

    return [
        { name: formatMessage({ id: 'followInstructions' }), style: { width: 'calc(100% / 6)' }, propertyName: 'text' },
        { name: '', style: { width: 'calc(100% / 6)' }, propertyName: 'image', imageType: 'circular', imageCount: 0, isFeed: false },
        { name: formatMessage({ id: 'attention' }), style: { width: 'calc(100% / 6)' }, propertyName: 'text' },
        { name: '', style: { width: 'calc(100% / 6)' }, propertyName: 'image', imageType: 'rectangle', imageCount: 0, isFeed: false },
        { name: formatMessage({ id: 'hyperactivity_impulsivity' }), style: { width: 'calc(100% / 6)' }, propertyName: 'text' },
        { name: '', style: { width: 'calc(100% / 6)' }, propertyName: 'image', imageType: 'diamond', imageCount: 0, isFeed: false },
    ]
}
export const table2Content2 = () => {
    const formatMessage = IntlManager.intl.formatMessage
    return [
        { index: '9', title: formatMessage({ id: 'performanceTraining' }), content: '' },
        // { index: '10', title: formatMessage({ id: 'strengths_advancementsChild' }), content: '' },
        // { index: '11', title: formatMessage({ id: 'aspectsImproved' }), content: '' },
        // { index: '12', title: formatMessage({ id: 'guardianFeedback_questions' }), content: '' },
    ]
}

export const table2Content2Title = () => {
    return [
        { name: '', style: { width: '40px', justifyContent: 'center' }, propertyName: 'index' },
        { name: '', style: { width: '15%', justifyContent: 'center' }, propertyName: 'title' },
        { name: '', style: { width: 'calc(85% - 40px)', padding: '2px 5px' }, propertyName: 'content' },
    ]
}

export const oneTomoreRable2Content2Title = () => {
    return [
        { name: '', style: { width: '24%', justifyContent: 'center', fontWeight: 'bold' }, propertyName: 'title' },
        { name: '', style: { width: '76%', padding: '2px 5px' }, propertyName: 'content' },
    ]
}