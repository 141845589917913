import React, { useEffect, useRef } from 'react'
import { connect, history, useIntl, getLocale } from 'umi'
import DocumentTitle from 'react-document-title'
import { Row, Col, Button, Modal, Divider, message, Spin, Empty, Form, Checkbox, Radio } from 'antd'
import PaginationCom from 'components/Pagination/Pagination'
import CourseCard from 'components/CourseCard'
import FeedbackDrawer from 'components/GroupFeedbackDrawer'
import { GetDownloadPdf } from 'services/puppeteer'
import { showErrorMsg } from 'cognitiveleap-core-us/utils/utils';
import { getDurationTime } from 'utils/utils';
import DownLoadModal from 'components/DownLoadModal';
import { GetChildList, GetChildListDetail } from 'services/group';
import styles from '../detail.less'

const GroupOfflinePlanDetail = ({ groupOfflinePlanDetail, dispatch, loading, currentUser, musicPlanInfo, musicIsOpen, location }) => {

  const intl = useIntl()

  const { displayName, groupId, className } = location.query

  const [downform] = Form.useForm();
  const [previewform] = Form.useForm();
  const durationRef = useRef();

  useEffect(() => {
    dispatch({ type: 'groupOfflinePlanDetail/pageLoading', payload: location.query })
  }, [])

  const {
    planItems,
    groupLinkUsers,
    pagination,
    drawerVisible,
    selectCourseInfo,
    selectGuardianCourseInfo,
    activeKey,
    childFeedbackData,
    activeTab,
    childEdit,
    downLoadModalVisible,
    duration,
    percent,
    isCurrentNum
  } = groupOfflinePlanDetail

  const { auth, changedTenant, staff } = currentUser
  const { grantedPolicies } = auth

  const hasOfflinePlanWritePermission = grantedPolicies['RocketSystem.UserOfflinePlan.Write'] === true
  const hasOfflinePlanReadPermission = grantedPolicies['RocketSystem.UserOfflinePlan'];
  const rocketProjectStatus = changedTenant && changedTenant.projectStatus ? changedTenant.projectStatus.find(item => item.name == 'Project.Rocket')['status'] : true;
  const isLoading = loading.models.groupOfflinePlanDetail
  const isBind = true
  const isLogout = false

  const paginationComProps = {
    onPageChange: (page, pageSize) => {
      const _pagination = {
        ...pagination,
        current: page,
        pageSize,
      }
      dispatch({ type: 'groupOfflinePlanDetail/changeTable', payload: { pagination: _pagination } })
    },
    ...pagination,
  }

  const sureFinishCourse = (userPlanId, num) => {
    dispatch({ type: 'groupOfflinePlanDetail/finishCourse', payload: { userPlanId, num } })
    dispatch({ type: 'musicplayer/closeGlobalMusicPlayer' })
  }

  const onFinishCourse = (userPlan, num) => {
    const { id: userPlanId } = userPlan
    Modal.confirm({
      title: intl.formatMessage({ id: 'reminder' }),
      content: intl.formatMessage({ id: 'report.offlinePlanInfo.cancel.title' }),
      okText: intl.formatMessage({ id: 'ok' }),
      cancelText: intl.formatMessage({ id: 'cancel' }),
      onOk() {
        if (musicIsOpen) {
          Modal.confirm({
            title: intl.formatMessage({ id: 'musicPlayingProceed' }),
            okText: intl.formatMessage({ id: 'leave' }),
            cancelText: intl.formatMessage({ id: 'cancel' }),
            onOk: () => {
              sureFinishCourse(userPlanId, num)
            },
          });
        } else {
          sureFinishCourse(userPlanId, num)
        }
      },
    })
  }

  const onStartNextCourse = (userPlanId, num) => {
    dispatch({ type: 'groupOfflinePlanDetail/startNextCourse', payload: { userPlanId, num } })
  }

  const onClose = () => {
    dispatch({
      type: 'groupOfflinePlanDetail/closeDrawer',
    })
  }

  const onChangeTabs = (key) => {
    dispatch({
      type: 'groupOfflinePlanDetail/updateState',
      payload: {
        activeTab: key,
        childEdit: false
      }
    })

    key === 'class' ? dispatch({
      type: 'groupOfflinePlanDetail/loadList',
      payload: {
        groupId
      }
    }) : dispatch({
      type: 'groupOfflinePlanDetail/loadChild'
    })
  };

  const drawerProps = {
    isBind,
    isLogout,
    activeKey,
    childFeedbackData,
    groupLinkUsers,
    hasOfflinePlanWritePermission,
    hasOfflinePlanReadPermission,
    visible: drawerVisible,
    allData: selectCourseInfo,
    rocketProjectStatus,
    loading: loading.models.groupOfflinePlanDetail,
    drawLoading: loading.models.groupOfflinePlanDetail,
    guardianCourseInfo: selectGuardianCourseInfo,
    onClose,
    isCurrentNum,
    onChangeTabs,
    activeTab,
    childEdit,
    onSubmit(feedbacks, trainingProgramId, planItemId, hasDone) {
      dispatch({
        type: 'groupOfflinePlanDetail/submitFeedback',
        payload: {
          feedbacks,
          trainingProgramId,
          planItemId,
          hasDone
        },
      })
    },
    onChildSubmit: async (feedbacks, groupUserCourseItemId, hasDone) => {
      dispatch({
        type: 'groupOfflinePlanDetail/childSubmitFeedback',
        payload: {
          feedbacks,
          groupUserCourseItemId,
          hasDone
        },
      })
    },
    onChangeCollapse(data, type, hasDone) {
      dispatch({
        type: 'groupOfflinePlanDetail/onChangeCollapse',
        payload: { activeKey: data, type, hasDone },
      })
    },
    onGuardianFeedback: (submitData, guardianHasDone) => {
      dispatch({
        type: 'groupOfflinePlanDetail/submitGuardianFeedback',
        payload: { submitData, guardianHasDone },
      })
    },
    onNotDo(id) {
      dispatch({
        type: 'groupOfflinePlanDetail/onNotDoItem',
        payload: id
      })
    },
    onGuardianNotDo({ id, guardianNum, notFinishAudios }) {
      dispatch({
        type: 'groupOfflinePlanDetail/onGuardianNotDoItem',
      })
    },
    eidtItem(data, type) {
      dispatch({
        type: 'groupOfflinePlanDetail/eidtItem',
        payload: {
          data,
          type
        }
      })
    },
  }

  // 预览下载pdf
  const previewOrDownloadPdf = async (type, userPlanId, num, startClassTime) => {

    // if (!startClassTime) {
    //   message.warning("请先开始上课后进行操作")
    //   return;
    // }

    let res;
    if (!startClassTime) {
      // 没有开始上课不能反馈
      res = await GetChildListDetail({
        groupId,
      });
    } else {
      res = await GetChildList({
        GroupTrainingPlanId: userPlanId,
        Num: num
      });
    }

    if (res.response.ok) {
      const groupLinkUsers = !startClassTime ? res.data.map((item) => {
        const { id } = item
        return {
          ...item,
          attendance: 'Attend',
          userId: id,
        }
      }) : res.data;

      dispatch({
        type: 'groupOfflinePlanDetail/updateState',
        payload: {
          groupLinkUsers
        }
      })

      const checkBoxData = groupLinkUsers.length > 0 && groupLinkUsers.filter(item => item.attendance === 'Attend').map(item => {
        return item.userId
      })

      const formItemLayout = {
        labelCol: { span: 6 },
        wrapperCol: { span: 14 },
      };

      const title = intl.formatMessage({
        id: type === 'download'
          ? 'selectChildDownloadLesson'
          : 'selectChildPreview'
      })

      const showContent =
        type === 'download' ? (
          <Form
            form={downform}
            {...formItemLayout}
            onFinish={async (data) => {
              const { checkChildList, downloadType } = data;

              let urls = [];
              let countDown;
              const origin = window.location.origin;
              const CognitiveleapUser =
                localStorage.getItem('CognitiveleapUser');
              const userTenantId = localStorage.getItem('userTenantId');
              const tenantId = localStorage.getItem('tenantId');
              const childNames = [];
              checkChildList.forEach((item) => {
                const childItem = groupLinkUsers.find(
                  (user) => user.userId === item,
                );
                const { userName } = childItem || {};

                const tempUrl =
                  origin +
                  `/hfsTrainingRecord?subjectId=${item}&groupTrainingPlanId=${userPlanId}&num=${num}&trainerId=${staff && staff.id
                  }&CognitiveleapUser=${CognitiveleapUser}&userTenantId=${userTenantId}&tenantId=${tenantId}&from=oneTomore&culture=${getLocale()}`;
                urls.push(tempUrl);
                childNames.push(userName);
              });
              const totalTime = getDurationTime(downloadType === 'totalDownLoad' ? urls.length : 1);
              durationRef.current = totalTime;
              countDown = setInterval(() => {
                if (durationRef.current > 0) {
                  durationRef.current--;
                  dispatch({
                    type: 'groupOfflinePlanDetail/updateState',
                    payload: {
                      percent: parseInt(
                        ((totalTime - durationRef.current) / totalTime) * 100,
                      ),
                      duration: durationRef.current
                    }
                  })
                }
              }, 1000);

              dispatch({
                type: 'groupOfflinePlanDetail/updateState',
                payload: {
                  downLoadModalVisible: true
                }
              })

              if (downloadType === 'totalDownLoad') {
                const fileBaseName =
                  childNames.map((item) => item + ' ') +
                  '-' +
                  (displayName ?? trainPlanName) +
                  '-';

                await GetDownloadPdf(
                  { urlList: { ...urls }, needPadding: true },
                  intl.formatMessage({ id: 'downLoadWhichFeedback' }, { fileBaseName, num })
                );
              } else {
                await Promise.all(urls.map((url, index) => {
                  const fileBaseName =
                    childNames[index] +
                    '-' +
                    (displayName ?? trainPlanName) +
                    '-';

                  return GetDownloadPdf(
                    { urlList: { url }, needPadding: true },
                    fileBaseName + '第' + num + '课时课程反馈',
                  )
                }))
              }

              dispatch({
                type: 'groupOfflinePlanDetail/updateState',
                payload: {
                  downLoadModalVisible: false
                }
              })

              clearInterval(countDown);
              dispatch({
                type: 'groupOfflinePlanDetail/updateState',
                payload: {
                  duration: 0,
                  percent: 0
                }
              })
            }}
            initialValues={{
              checkChildList: checkBoxData,
              downloadType: 'totalDownLoad'
            }}
            style={{ marginTop: '20px' }}
          >
            <Form.Item name="checkChildList" label={intl.formatMessage({ id: 'subjectList' })}>
              <Checkbox.Group>
                {groupLinkUsers.length > 0 &&
                  groupLinkUsers.map((item) => {
                    const { userName, userId, attendance } = item;
                    return (
                      <Checkbox
                        key={userId}
                        value={userId}
                        disabled={attendance !== 'Attend'}
                        style={{ lineHeight: '32px' }}
                      >
                        {userName}
                      </Checkbox>
                    );
                  })}
              </Checkbox.Group>
            </Form.Item>
            <Form.Item
              name="downloadType"
              label="下载方式"
            >
              <Radio.Group>
                <Radio.Button value="totalDownLoad">合并下载</Radio.Button>
                <Radio.Button value="oneDownLoad">分别下载</Radio.Button>
              </Radio.Group>
            </Form.Item>
          </Form>
        ) : (
          <Form
            form={previewform}
            {...formItemLayout}
            onFinish={async (data) => {
              const { checkChild } = data;

              const previewUrl = `/hfsTrainingRecord?subjectId=${checkChild}&groupTrainingPlanId=${userPlanId}&num=${num}&trainerId=${staff && staff.id
                }&type=preview&from=oneTomore&culture=${getLocale()}`;

              window.open(previewUrl, '_blank');
            }}
            initialValues={{
              checkChild: checkBoxData[0],
            }}
            style={{ marginTop: '20px' }}
          >
            <Form.Item name="checkChild" label={intl.formatMessage({ id: 'subjectList' })}>
              <Radio.Group>
                {groupLinkUsers.length > 0 &&
                  groupLinkUsers.map((item) => {
                    const { userName, userId, attendance } = item;
                    return (
                      <Radio
                        key={userId}
                        value={userId}
                        disabled={attendance !== 'Attend'}
                        style={{ lineHeight: '32px' }}
                      >
                        {userName}
                      </Radio>
                    );
                  })}
              </Radio.Group>
            </Form.Item>
          </Form>
        );

      Modal.confirm({
        title: title,
        width: 600,
        content: showContent,
        onOk() {
          type === 'download' ? downform.submit() : previewform.submit();
        },
      });
    } else {
      showErrorMsg(res, intl);
    }
  }

  const downLoadProps = {
    visible: downLoadModalVisible,
    percent,
    duration,
  }

  return (
    <DocumentTitle title={intl.formatMessage({ id: 'trainingPlanDetails' })}>
      <React.Fragment>
        <Row
          type="flex"
          justify="start"
          gutter={20}
          className={styles.top}
          style={{ marginBottom: 14 }}
        >
          <Col>
            <Button onClick={() => history.goBack()}>{intl.formatMessage({ id: 'back' })}</Button>
          </Col>
        </Row>
        <Spin spinning={isLoading}>
          <div className={styles.mainOld}>
            <div style={{ backgroundColor: 'white', padding: '16px 0' }}>
              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <span className={styles.topTitle}>{displayName && decodeURIComponent(displayName)}</span>
              </div>
              <Divider />
              {planItems.length > 0 ? <>
                <Row gutter={[12, 12]} style={{ padding: '10px 16px' }}>
                  {
                    planItems.map(item => {
                      const { num, endTime, groupTrainingPlanId, courseItems, startClassTime, endClassTime, feedbacks } = item

                      // 整体反馈有填写提交时间则显示提交时间，否则显示反馈实际提交时间
                      let tempEndTime = endTime;
                      if (feedbacks.length > 0) {
                        const data = feedbacks[0].feedbackProperties.find(item => item.name === 'courseTime' && item.type === 'TimePicker')
                        const { value } = data || {}
                        if (value !== undefined && value !== null) {
                          tempEndTime = value
                        }
                      }

                      const { total } = pagination
                      const courseCardProps = {
                        type: 'v2',
                        isFixed: true,
                        showTop: true,
                        allData: {
                          ...item,
                          userOfflineTrainingPlan: {
                            ...item,
                            id: groupTrainingPlanId
                          },
                          planItems: courseItems,
                          isAdjustment: false,
                          showNextClass: num === total && endTime,
                          numPunchOutput: item,
                        },
                        hasOfflinePlanWritePermission,
                        hasOfflinePlanReadPermission,
                        rocketProjectStatus,
                        isBind,
                        isLogout,
                        isCurrent: num === total, /// TODO 可能要自己算
                        endTime: tempEndTime,
                        from: 'groupOfflinePlanDetail',
                        onClickCard: (groupTrainingPlanId, num) => {
                          history.push({
                            pathname: '/system/center/detail/userOfflineCourseDetail',
                            query: {
                              userPlanId: groupTrainingPlanId,
                              num,
                              currentNum: total,
                              from: 'groupOfflinePlanDetail',
                              userOrClassName: className,
                              trainPlanName: displayName,
                            },
                          });
                        },
                        onFinishCourse: onFinishCourse,
                        onStartNextCourse: onStartNextCourse,
                        onFeedback: async (userPlanId, num) => {
                          // 没有开始上课，没有结束上课都不能反馈
                          dispatch({
                            type: 'groupOfflinePlanDetail/updateState',
                            payload: {
                              CourseNum: num
                            }
                          })
                          if (startClassTime) {
                            if (endClassTime) {
                              await dispatch({ type: 'groupOfflinePlanDetail/onFeedback', payload: { userPlanId, num } })
                            } else {
                              message.warning(intl.formatMessage({ id: 'sessionFirstGiveFeedback' }))
                            }
                          } else {
                            message.warning(intl.formatMessage({ id: 'giveFeedbackSession' }))
                          }
                        },
                        previewHfsRecord: (userPlanId, num) => {
                          previewOrDownloadPdf('preview', userPlanId, num, startClassTime)
                        },
                        onDownloadHfsRecord: (userPlanId, num) => {
                          previewOrDownloadPdf('download', userPlanId, num, startClassTime)
                        },
                      }
                      return (
                        <Col xs={24} md={12} lg={8} xl={8} xxl={6} key={num}>
                          <CourseCard {...courseCardProps} />
                        </Col>
                      )
                    })
                  }
                </Row>
                <div style={{ marginLeft: '16px' }}>
                  <PaginationCom {...paginationComProps} />
                </div>
              </> : <Empty />}
              <FeedbackDrawer {...drawerProps} />
            </div>
          </div>
        </Spin>
        <DownLoadModal {...downLoadProps} />
      </React.Fragment>
    </DocumentTitle>
  )
}

export default connect(({ groupOfflinePlanDetail, user, loading, musicplayer }) => ({
  groupOfflinePlanDetail,
  currentUser: user.currentUser,
  loading,
  musicPlanInfo: musicplayer.planInfo,
  musicIsOpen: musicplayer.isOpen,
}))(GroupOfflinePlanDetail)
